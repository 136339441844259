import React from 'react';
import { Link, graphql } from 'gatsby';
import BoxRows from 'components/box/box-rows/box-rows';
import Img from 'gatsby-image';
import { FullWidthImg } from 'components/image-wrappers';
import Title from 'components/title';
import Layout from 'components/layout';
import Stack from 'components/stack';
import StackItem from 'components/stack/stack-item';
import {
  TextWrapper,
  TextUnderline,
  Body,
  Description,
  StickyText,
  ProjectBox,
  ProjectInfo,
  BackgroundText,
  NavigationWrapper,
  NavButtons,
  NavSubText,
  ImgTitle,
  ImgWrapper,
  HeroImgContainer,
  BodyImgWrapper
} from 'components/GlobalComponents';

const CookingUX = ({ data }) => {
  const project = data.cookinguxJson;
  const [next, nextTitle] = [
    '/couchsurfing',
    'Investigating Couchsurfing Opinion',
  ];

  return (
    <Layout>
      <HeroImgContainer>
        <FullWidthImg
          key={project.gallery.hero.id}
          opacity={0.1}
          fluid={project.gallery.hero.childImageSharp.fluid}
        />
        <ImgTitle>{project.title}</ImgTitle>
      </HeroImgContainer>

      <TextWrapper>
        <BoxRows>
          <Stack>
            <StackItem>
              <Title as="h4">ROLE</Title>
              <BackgroundText>{project.role}</BackgroundText>
            </StackItem>
            <StackItem>
              <Title as="h4">CONTEXT</Title>
              <BackgroundText>{project.context}</BackgroundText>
            </StackItem>
            <StackItem>
              <Title as="h4">YEAR</Title>
              <BackgroundText>{project.year}</BackgroundText>
            </StackItem>
            <StackItem>
              <Title as="h4">METHODS</Title>
              <BackgroundText
                dangerouslySetInnerHTML={{
                  __html: project.methods.childMarkdownRemark.html,
                }}
              ></BackgroundText>
            </StackItem>
          </Stack>
          <ProjectInfo>
            <Title as="h3">Overview</Title>
            <TextUnderline />

            <Description
              dangerouslySetInnerHTML={{
                __html: project.overview.childMarkdownRemark.html,
              }}
            />
            <Title as="h4">Research Goals and Questions:</Title>
            <TextUnderline />
            <Description
              dangerouslySetInnerHTML={{
                __html: project.goals.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </BoxRows>

        <ProjectBox>
          <Title as="h4">Introduction</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.introduction.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>

          <Title as="h4">Secondary Research:</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.secondaryResearch.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
          <Title as="h4">Methodology</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.methodology.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
          <Title as="h4">Recruitment Sample/Ideal Sample:</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.recruitmentSample.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>
        {/* 
      <BoxRows>
        <StickyText height={230}> 
          <Body>
          <Description dangerouslySetInnerHTML={{ __html: project.fieldStudyExecution.childMarkdownRemark.html }} />
          </Body>
        </StickyText>
        <>
          <ImgWrapper>
            <Img fluid={project.gallery.fieldStudyImg.childImageSharp.fluid} />
          </ImgWrapper>
        </>
      </BoxRows> */}

        <ProjectBox>
        <Title as="h4">Conducting Surveys</Title>
          <TextUnderline />
          <ImgWrapper>
            <Img
              fluid={project.gallery.surveyCognitiveInterview.childImageSharp.fluid}
            />
          </ImgWrapper>
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.surveyExecution.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
          <Title as="h4">Conducting Field Study</Title>
          <TextUnderline />
          <>
            <ImgWrapper>
              <Img
                fluid={project.gallery.fieldStudyImg.childImageSharp.fluid}
              />
            </ImgWrapper>
          </>
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.fieldStudyExecution.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>

        <ProjectBox>
        <Title as="h4">Results of Survey</Title>
          <TextUnderline />
          <ImgWrapper>
            <Img
              fluid={project.gallery.surveyResultsImg.childImageSharp.fluid}
            />
          </ImgWrapper>
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.surveyResult.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
          <Title as="h4">Results of Field Study</Title>
          <TextUnderline />
          {/* <>
            <ImgWrapper>
              <Img
                fluid={project.gallery.fieldStudyImg.childImageSharp.fluid}
              />
            </ImgWrapper>
          </> */}
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.fieldStudyResult.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>

        <ProjectBox>
          <Title as="h4">Synthesis:</Title>
          <TextUnderline />
          <BodyImgWrapper>
            <Img fluid={project.gallery.graph.childImageSharp.fluid} />
          </BodyImgWrapper>
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.synthesis.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
          <BodyImgWrapper>
            <Img fluid={project.gallery.taskAnalysis.childImageSharp.fluid} />
          </BodyImgWrapper>
        </ProjectBox>

        <ProjectBox>
          <Title as="h4">Key Takeaway:</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.takeaway.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>
      </TextWrapper>
      <NavigationWrapper background={'#f7d68b'}>
        <Link to={`${next}`}>
          <NavButtons line>
            <NavSubText>Next &#8594;</NavSubText>
            <Title as="span">{nextTitle}</Title>
          </NavButtons>
        </Link>
      </NavigationWrapper>
    </Layout>
  );
};

export const query = graphql`
  query CookingUXQuery {
    cookinguxJson {
      title
      year
      role
      context
      methods {
        childMarkdownRemark {
          html
        }
      }
      overview {
        childMarkdownRemark {
          html
        }
      }
      goals {
        childMarkdownRemark {
          html
        }
      }
      introduction {
        childMarkdownRemark {
          html
        }
      }
      secondaryResearch {
        childMarkdownRemark {
          html
        }
      }
      methodology {
        childMarkdownRemark {
          html
        }
      }
      recruitmentSample {
        childMarkdownRemark {
          html
        }
      }
      fieldStudyExecution {
        childMarkdownRemark {
          html
        }
      }
      surveyExecution {
        childMarkdownRemark {
          html
        }
      }
      fieldStudyResult {
        childMarkdownRemark {
          html
        }
      }
      surveyResult {
        childMarkdownRemark {
          html
        }
      }
      synthesis {
        childMarkdownRemark {
          html
        }
      }
      takeaway {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        hero {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        fieldStudyImg {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        surveyCognitiveInterview {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        surveyResultsImg {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        taskAnalysis {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        graph {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;


export default CookingUX;
